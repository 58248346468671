import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      
      {
        color: 'primary',
        label: "جديدة",
        id:0
      },
      {
        color: 'success',
        label: 'مقيولة',
        id:1
      },
      {
        color: 'danger',
        label: 'مرفوضة',
        id:2
      },
      
    ],
    selectedCalendars: [1,0, 2],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx) {
    
      return new Promise((resolve, reject) => {
        //&user=${queryParams.user}
        axios
          .get(`/api/v1/holidays`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   
    addEvent(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/holidays',data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEventReplay(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/holidays`,data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, data ) {
      //console.log('da',data)
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/holidays/${data.id}`,{
            date:data.start,
            title:data.title
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/holidays/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
